<template>
  <section class="sub-page iframe">
    <!-- herosection -->
    <div class="relative mt-20 mb-20">
      <img
        src="/theme-assets/images/dashboardImg.png"
        alt=""
        class="h-[260px] w-full"
      />
      <div
        class="absolute md:top-[100px] top-20 left-[15%] md:left-[25%] right-[15%] md:right-[25%] text-center"
      >
        <div class="py-10 px-10 md:px-20 bg-[#262637]">
          <p class="text-[#1cfa78] text-3xl">Market Directory</p>
          <p class="text-[#8c8c92]">
            Get easy access to housing market professionals & institutions based
            on their functions
          </p>
          <form class="" @submit.prevent="calculateMortgage">
            <div class="flex md:gap-8 mt-8 md:flex-row flex-col gap-1">
              <select
                id="states"
                v-model="form.state"
                name="states"
                class="w-full rounded-sm p-2 md:w-[20%]"
              >
                <option disabled selected value="">Select a state</option>
                <option v-for="(state, i) in states" :key="i" :value="state">
                  {{ state.stateName }}
                </option>
              </select>
              <select
                id="options"
                v-model="form.type"
                name="type"
                class="w-full rounded-sm p-2 md:w-[20%]"
              >
                <option disabled value="">Select Role</option>
                <option value="Solicitor">Solicitor</option>
                <option value="Agent">Agent</option>
              </select>

              <button
                class="bg-[#1cfa78] text-[#262637] px-4 rounded-sm"
                @click="filterByState()"
              >
                Search
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>
    <!-- Why People Choose us -->
    <div v-if="agents.length == 0" class="md:w-[70%] mx-auto w-[90%] mt-36">
      <p class="pb-4 text-lg">
        We are still loading developers for
        {{ form?.state?.stateName }} State<br />
        Please check again later.
      </p>
    </div>
    <div v-if="agents.length > 0" class="md:w-[70%] mx-auto w-[90%] mt-36">
      <div
        v-for="(agent, i) in agents"
        :key="i"
        class="flex bg-[#FF0000] text-white items-start mb-10 w-full gap-4 md:w-[40%]"
      >
        <div>
          <img
            src="/theme-assets/images/housing.png"
            alt=""
            class="w-[91px] h-[71px] object-cover"
          />
        </div>
        <div class="">
          <p class="pb-4 text-emerald-700 text-lg">
            {{ agent.agent }}
          </p>
          <p class="text-xs">
            <i class="fa fa-user" />
            {{ agent.type }}
          </p>
          <p class="text-xs">
            <i class="ti-location-pin" />
            {{ agent.address }}
          </p>
          <p class="text-xs">Tel: {{ agent.tel1 }}, {{ agent.tel2 }}</p>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import Form from "vform";
import states from "./states.json";
import agents from "./agents.json";

export default {
  name: "HousingMarket",
  components: {},

  data: () => ({
    nav_title: "Housing Market Directory",
    navs: [
      {
        route_name: "housing.market",
        route_title: "Housing Market Directory",
      },
    ],
    visible: true,
    iframe: {
      src: "https://app.powerbi.com/view?r=eyJrIjoiNWVhNTM4OTItNGMwNS00MjIwLWI5ZWUtYWQ4MzQxNmMwNTAyIiwidCI6ImE4MTNjYTk4LWU0MTMtNDJkMS1iNzNmLTlmZGM3YTJhYjhkNCIsImMiOjh9",
      loaded: false,
    },
    states: states,
    agents: agents,
    originalAGents: agents,
    form: new Form({
      state: "",
      type: "",
    }),
  }),

  methods: {
    load() {
      this.iframe.loaded = true;
    },
    filterByState() {
      this.agents = this.originalAGents;
      let selectedState = this.form.state.stateName?.toLocaleLowerCase();
      let selectedType = this.form.type;

      if (selectedState.length) {
        this.agents = this.agents.filter(
          (x) => x.state?.toLocaleLowerCase() == selectedState
        );
      }

      if (selectedType.length) {
        this.agents = this.agents.filter((x) => x.type == selectedType);
      }
    },
  },
};
</script>
<style scoped>
.iframe {
  overflow-y: hidden;
}
</style>
